.work{
  padding: 0 3rem;
  display: flex;
  margin-bottom: 10rem;
}
.leftii{
  margin-top: 1rem;
}
.work-right{
  position: relative;
  margin-top: 3.5rem;
}
.main-circle{
  position: relative;
  background: white;
  height: 18rem;
  width: 18rem;
  border-radius: 100%;
  box-shadow: var(--smboxShadow);

}
.main-circle{
  position: absolute;
  /* top: 0; */
  left: 7rem;
}
.main-circle>:nth-child(1){
  top: -3rem;
  left: 5.7rem;
}
.main-circle>:nth-child(2){
  top: 5rem;
  left: -3rem;
}
.main-circle>:nth-child(3){
  top: 6rem;
  left: 6rem;
}
.main-circle>:nth-child(4){
  top: 5rem;
  left: 15rem;
}
.main-circle>:nth-child(5){
  top: 14rem;
  left: 6rem;
}
.second-circle{
  position: absolute;
  height: 6rem;
  width: 6rem;
  border-radius: 100%;
  border: 5px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: var(--smboxShadow);
}
.second-circle>img{
  transform: scale(0.6);
}
.back-circle{
  position: absolute;
  height: 13rem;
  width: 13rem;
  border-radius: 100%;
  z-index: -1;
}
.blue-circle{
  background: #1949b8;
  top: 0 ;
  left: 18rem;
}
.yellow-circle{
  background: #F5C32C;
  left: 18rem;
  top: 8rem;
}