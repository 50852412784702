*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root
{
  --yellow: #F5C32C;
    --orange : #FCA61F;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App{
    padding: 0.5rem 3.5rem;
    overflow: hidden;
    color: var(--black);    
    margin-top: 10px;
}
.btn{
  border: none;
  border-radius: 34px;
  color: white;
  font-size: 16px;
  padding: 11px 26px;
  cursor: pointer;
  background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
}
.btn:hover{
 background: white;
 color: var(--orange);
 border:inset 0 0 0 1px var(--orange);
}
.toggle{
  display: flex;
  justify-content: space-between;
  border: 3px solid var(--orange);
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  padding: 2px;
}
.toggle>*{
  width: 1rem;
  height: 1rem;
  color: var(--orange);
}
.t-button{
  /* width: 2px; */
  border-radius: 100%;
  background: var(--orange);
  position: absolute;
}
  