.cards{
  display: flex;
  flex-direction: column;
  height: 15rem;
  width: 13rem;
  position: absolute;
  gap: 1rem;
  align-items: center;
  text-align: center;
  background: rgba(255, 255, 255, 0.26);
  border: 7px solid var(--orangeCard);
  box-shadow: var(--boxShadow);
  border-radius: 20px;
  padding: 0 26px 2px 26px;
}



.cards>:nth-child(3){
  color: var(--gray);
  font-size: 16px;
}
.cards>img{
  transform: scale(0.6);
  margin-bottom: -2rem;
  margin-top: -1rem;
}

.c-btn{
  background: white;
  border: none;
  padding: 10px;
  box-shadow: 0 19px 60px rgba(0, 0, 0, 0.08);
  font-size: 16px ;
  color: #5290fd;
}