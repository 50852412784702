.intro{
  margin-top: 6rem;
  /* height: 77vh; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10rem;
}
.intro-left{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.intro-left>div{
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
}
.intro-left>div>:nth-child(1){
  font-size: 3rem;
  color: var(--black);
  font-weight: bold;
}
.intro-left>div>:nth-child(2){
  font-size: 3rem;
  color: var(--orange);
  font-weight: bold;
}
.intro-left>div>:nth-child(3){
font-size: 14px;
font-weight: 100;
color: var(--gray);
}
.i-btn{
  width: 6rem;
  height: 2rem;
}
.i-pics{
  margin-top: 1.8rem;
  display: flex !important;
  gap: 2rem !important;
}
.i-pics>img{
  height: 2rem;
  width: 2rem;
  /* transform: scale(0.5); */
}
.i-pics>img{
  cursor: pointer;
}




.intro-right{
position: relative;
}
.intro-right>*{
  position: absolute;
  z-index: 1;
}
.intro-right>:nth-child(1){
  transform: scale(0.69);
  left: -15%;
  top: -9%;
}
.intro-right>:nth-child(2){
  transform: scale(0.67);
  left: -3rem;
  top: -4.6rem;
}
.intro-right>:nth-child(3){
  transform: scale(1.4);
  left: 28%;
}
.intro-right>:nth-child(4){
  transform: scale(0.6);
  top: -19%;
  left: -24%;
  border-radius: 50%;
}
.blur{
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #edd0ff;
  filter: blur(72px);
  z-index: -9;
}