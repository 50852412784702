.services{
  display: flex;
  padding: 0 3rem;
  margin-bottom: 25rem;
  margin-top: 13rem;
}
.services-left{
  display: flex;
  flex-direction: column;
  position: relative;
  /* flex: 1; */
}
.services-left>:nth-child(1){
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}
.services-left>:nth-child(2){
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}
.services-left>:nth-child(3){
  color: var(--gray);
  font-size: 14px;
  margin-top: 1rem;
}
.services-left>:nth-child(4){
  width: 8rem;
  height: 2rem;
  margin-top: 1rem;
}

.s-blur{
  top: 13rem;
  left: -18rem;
}
.s-blur-2{
left: 14rem;
top: 8rem;
}






.services-right{
  display: flex;
  flex-direction: column;
  position: relative;
  /* flex: 1; */
}
.services-right>*{
  position: absolute;
}
