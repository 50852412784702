.footer{
  position: relative;
}
.footer>img{
  width: 100%;
  position: relative;
}
.icons{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 13rem;
  left: 22.5rem;
  gap: 1rem;

}
.icons>*{
  height: 3rem;
  width: 3rem;
  cursor: pointer;
}
.icons>*:hover{
  transform: scale(1.2);
}
.icons>:nth-child(1){
  color: 	#4267B2;
}
.icons>:nth-child(2){
  color: 	#171515;
}
.icons>:nth-child(3){
  color: 	 #E1306C;
}