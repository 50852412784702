.float{
  display: flex;
  justify-content: space-between;
  background: white;
  box-shadow: var(--boxShadow);
  border-radius: 17px;
  align-items: center;
  padding: 0 28px 0 0;
  height: 4.5rem;
}
.float>img{
transform: scale(0.4);
}
span{
  font-size: 16px;
  font-family: sans-serif;
}