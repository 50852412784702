.portfolio{
  margin-top: 16rem;
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
}
.profolio-color{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profolio-color>:nth-child(1){
  font-size: 2rem;
  color: var(--black);
  font-weight: bold;
}
.profolio-color>:nth-child(2){
  font-size: 2.5rem;
  color: var(--orange);
  font-weight: bold;
  margin-bottom: 3rem;
}
.portfolio .swiper{
  overflow: visible !important;
}
.portfolio-slider{
  width: 100%;
  margin-top: 3rem;
}

.portfolio-slider .swiper-slider{
 width: 20rem;
}
.portfolio img{
  width: 19rem;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0 , 0, 0.25));
  border-radius: 19px;
}